import Radio from 'components/forms/Radio';
import RadioGroup from 'components/forms/RadioGroup';
import { FormattedMessage } from 'react-intl';

export const GENDER_PREFERENCE = {
  Male: {
    value: 'M',
    label: <FormattedMessage defaultMessage={`Männlich`} id="AfBCtO" />,
  },
  Female: {
    value: 'F',
    label: <FormattedMessage defaultMessage={`Weiblich`} id="2T9/1l" />,
  },
  Neutral: {
    value: 'N',
    label: <FormattedMessage defaultMessage={`Neutral`} id="URL784" />,
  },
} as const;

type ObjectValues<T> = T[keyof T];
export type GenderPreference = ObjectValues<typeof GENDER_PREFERENCE>;

export default function GenderPreferenceStep({
  setGenderPreference,
  genderPreference,
}) {
  return (
    <div className="mt-4">
      <RadioGroup>
        <Radio
          id={`gender-male`}
          name="variationselection"
          onClick={() => setGenderPreference(GENDER_PREFERENCE.Male)}
          checked={genderPreference.value == GENDER_PREFERENCE.Male.value}
          onChange={() => {}}
          label={GENDER_PREFERENCE.Male.label}
        />
        <Radio
          id={`gender-female`}
          name="variationselection"
          onClick={() => setGenderPreference(GENDER_PREFERENCE.Female)}
          checked={genderPreference.value == GENDER_PREFERENCE.Female.value}
          onChange={() => {}}
          label={GENDER_PREFERENCE.Female.label}
        />
        <Radio
          id={`gender-neutral`}
          name="variationselection"
          onClick={() => setGenderPreference(GENDER_PREFERENCE.Neutral)}
          checked={genderPreference.value == GENDER_PREFERENCE.Neutral.value}
          onChange={() => {}}
          label={GENDER_PREFERENCE.Neutral.label}
        />
      </RadioGroup>
    </div>
  );
}
