export interface startAndEndDate {
  // Start date of format: Y-m-d
  startDate: string;
  // End date of format: Y-m-d
  endDate: string;
}

export function getTicketDate(selectedDate: Date): startAndEndDate {
  return {
    startDate: convertDateYmd(selectedDate),
    endDate: convertDateYmd(selectedDate),
  };
}

export function getStartAndEndDate(
  dayInShownMonth: Date,
  preloadYear: boolean = false
): startAndEndDate {
  if (preloadYear) {
    return {
      startDate: convertDateYmd(
        new Date(dayInShownMonth.getFullYear(), dayInShownMonth.getMonth())
      ),
      endDate: convertDateYmd(
        new Date(dayInShownMonth.getFullYear() + 1, dayInShownMonth.getMonth())
      ),
    };
  }

  // We load additional days of the previous and the next month because they
  // can also be shown on the datepicker.
  return {
    startDate: convertDateYmd(
      new Date(dayInShownMonth.getFullYear(), dayInShownMonth.getMonth(), -6)
    ),
    endDate: convertDateYmd(
      new Date(
        dayInShownMonth.getFullYear(),
        dayInShownMonth.getMonth() + 1,
        20
      )
    ),
  };
}

// Converts date to a Y-m-d date string using fr-CA.
export function convertDateYmd(date: Date): string {
  return date.toLocaleDateString('fr-CA');
}

// Converts date to a 2023-01-10T14:31:04.506Z date string.
export function convertDateIso(date: Date): string {
  return date.toISOString();
}

// Converts date to hours:seconds time
export function convertToTime(date: Date): string {
  return Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
    hour12: false,
  }).format(date);
}

// Converts date to "24. Dezember 2022" format
export function convertToDate(date: Date): string {
  return Intl.DateTimeFormat('de', {
    dateStyle: 'long',
  }).format(date);
}
// Converts date to hours:seconds time
export function convertToUTCTime(date: Date): string {
  return Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
    hour12: false,
    timeZone: 'UTC',
  }).format(date);
}

// Converts date to "24. Dezember 2022" format
export function convertToUTCDate(date: Date): string {
  return Intl.DateTimeFormat('de', {
    dateStyle: 'long',
    timeZone: 'UTC',
  }).format(date);
}

// Converts date to Y-m-dThh:mm.
export function convertToDateAndTime(date: Date) {
  return convertDateYmd(date) + 'T' + convertToUTCTime(date);
}

// Converts date to "30.03.2023" format
export function convertToDotFormat(date: Date) {
  return Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
    .format(date)
    .replace(/\//g, '.');
}
