import { ReactChild } from 'react';

interface RadioGroupProps {
  children: ReactChild | ReactChild[];
  label?: string | JSX.Element;
}

export default function RadioGroup({ children, label }: RadioGroupProps) {
  return (
    <div className="mt-2 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4">
      {label && (
        <label className="col-span-2 pt-1 text-base-m font-light lg:pb-3 lg:text-base-d">
          {label}
        </label>
      )}
      <div className="col-span-6 flex flex-col flex-wrap gap-4 md:gap-x-8 lg:col-span-4 lg:flex-row lg:gap-x-16 lg:gap-y-8">
        {children}
      </div>
    </div>
  );
}
