import { ReactNode } from 'react';

interface RadioProps {
  id: string;
  name: string;
  checked?: boolean;
  value?: string;
  defaultChecked?: boolean;
  label?: ReactNode;
  register?: any;
  onClick?: () => void;
  onChange?: () => void;
}

export default function Radio({ register, label, ...props }: RadioProps) {
  return (
    <div className="flex items-center">
      <input
        className="relative h-4 w-4 cursor-pointer rounded-full border-0 text-tt-white before:absolute before:top-[2px] before:left-[2px] before:h-3 before:w-3 before:rounded-full checked:bg-none checked:before:bg-primary focus:outline-none focus:ring-0 focus:ring-offset-0 lg:h-8 lg:w-8 before:lg:top-1 before:lg:left-1 before:lg:h-6 before:lg:w-6"
        type="radio"
        {...props}
        {...register}
      />
      {label && (
        <label
          className="cursor-pointer pl-2 text-base-m font-normal lg:pl-3 lg:text-base-d"
          htmlFor={props.id}
        >
          {label}
        </label>
      )}
    </div>
  );
}
