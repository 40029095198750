import { GenderPreference } from 'components/Commerce/Steps/GenderPreferenceStep/GenderPreferenceStep';
import { convertDateIso, convertDateYmd } from './date';
import { getGenderParameter } from './reservation-client';

const url = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

interface BookingInfo {
  bookingId: string;
  date: string;
  title: string;
  templateId: string;
}

export const getDeletionStringForBookedIds = (variation) => {
  // Collect reservation booking ids.
  const bookingIdsToDelete =
    variation?.meta?.fields?.field_reservation_start_dates?.map(
      (bookingInfo) => convertBookingStringToBookingInfo(bookingInfo).bookingId
    ) ?? [];
  // Get package booking id.
  if (variation?.meta?.fields?.field_booking_id?.[0]) {
    bookingIdsToDelete.push(variation.meta.fields.field_booking_id[0]);
  }
  return bookingIdsToDelete.join(',');
};

export const getTreatmentDates = (timeslotResponse, packageSku) => {
  // The Availability with the matching SKU is the package itself.
  const bookingInformation =
    timeslotResponse?.body_content?.Availabilities?.Availability.filter(
      (availability) =>
        availability?.TemplateIDs.UniqueID[0].value != packageSku
    ).map((availability) => {
      const startDate = availability.TimeSpan?.Start;
      const endDate = availability?.TimeSpan?.End;
      const title = availability?.title;
      const bookingId = availability?.BookingIDs?.UniqueID?.[0]?.value;
      const templateId = availability?.TemplateIDs?.UniqueID?.[0]?.value;
      return { bookingId, startDate, endDate, title, templateId };
    });

  return bookingInformation;
};

/**
 * Filter the timeslot response to get the booking ids for the package.
 *
 * @param timeslotResponse
 *   The response from the timeslot endpoint.
 * @param packageSku
 *   The SKU of the package.
 * @returns
 *   An array of booking ids.
 */
export const getPackageBookingIds = (
  timeslotResponse,
  packageSku: string
): Array<number> => {
  const bookingIdPackage =
    timeslotResponse?.body_content?.Availabilities?.Availability.filter(
      (availability) => {
        return availability?.TemplateIDs.UniqueID[0].value == packageSku;
      }
    ).map((availability) => {
      return availability?.BookingIDs?.UniqueID?.[0]?.value;
    });
  return bookingIdPackage;
};

export const convertBookingInfoToString = (
  bookingId,
  date,
  title,
  templateId
) => {
  return `${bookingId}|${date}|${title}|${templateId}`;
};

export const convertBookingStringToBookingInfo = (
  bookingString
): BookingInfo => {
  const split = bookingString.split('|');
  return {
    bookingId: split[0],
    date: split[1],
    title: split[2],
    templateId: split[3],
  };
};

export const getEarliestTimeslot = (bookingStrings: Array<string>) => {
  return bookingStrings
    .map((bookingString) => convertBookingStringToBookingInfo(bookingString))
    .reduce((accumulator, current) =>
      new Date(current.date) < new Date(accumulator.date)
        ? current
        : accumulator
    );
};

// Get the available time slots for a reservation
export async function getAvailablePackageTimeSlot(
  productId: string,
  date: Date,
  gender: GenderPreference,
  quantity: number,
  bookingsToDelete: string
) {
  // TAC uses UTC timezone.
  const startDate = date;
  const endDate = new Date(date.getTime());
  endDate.setUTCHours(23, 59, 59, 999);
  const response = await fetch(
    `${url}/de/api/package-availability/time-slots?product_id=${productId}&start_date=${convertDateIso(
      startDate
    )}&end_date=${convertDateIso(endDate)}${getGenderParameter(
      gender
    )}&persons=${quantity}${
      bookingsToDelete ? `&old_booking_ids=${bookingsToDelete}` : ``
    }`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    console.log('Get available time slots response status: ' + response.status);
    console.log('Get available time slots response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}
