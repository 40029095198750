import {
  GENDER_PREFERENCE,
  GenderPreference,
} from 'components/Commerce/Steps/GenderPreferenceStep/GenderPreferenceStep';
import { convertDateIso, startAndEndDate } from './date';

const url = process.env.NEXT_PUBLIC_DRUPAL_BASE_URL;

// Get the dates for a reservation
export async function getAvailableDatesForReservations(
  variations: Array<any>,
  dates: startAndEndDate
) {
  const productId = variations?.[0]?.id;
  const response = await fetch(
    `${url}/de/api/reservation-availability/dates?product_id=${productId}&start_date=${dates.startDate}&end_date=${dates.endDate}`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    console.log('Get available dates response status: ' + response.status);
    console.log('Get available dates response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Get the available time slots for a reservation
export async function getAvailableReservationTimeSlots(
  productId: string,
  date: Date,
  gender: GenderPreference,
  quantity: number
) {
  // TAC uses UTC timezone.
  const startDate = new Date(date.getTime());
  startDate.setUTCHours(0, 0, 0, 0);
  const endDate = new Date(date.getTime());
  endDate.setUTCHours(23, 59, 59, 999);
  const response = await fetch(
    `${url}/de/api/reservation-availability/time-slots?product_id=${productId}&start_date=${convertDateIso(
      startDate
    )}&end_date=${convertDateIso(endDate)}${getGenderParameter(
      gender
    )}&person=${quantity}`,
    {
      method: 'GET',
    }
  );
  if (!response.ok) {
    console.log('Get available time slots response status: ' + response.status);
    console.log('Get available time slots response: ', await response.json());
    return false;
  } else {
    return await response.json();
  }
}

// Create gender parameter according to the API
export function getGenderParameter(gender: GenderPreference) {
  return gender === GENDER_PREFERENCE.Neutral ? '' : `&gender=${gender.value}`;
}

// Convert the reservation-availability response to an array of js Dates
export function convertAvailableReservationDates(response) {
  const bodyContent = response?.body_content;

  if (bodyContent?.Result?.resultStatusFlag === 'FAIL') {
    return [];
  }
  const possibilities = bodyContent?.Possibilities?.Possibility;

  return possibilities?.map((date) => {
    return new Date(Date.parse(date?.TimeSpan?.Start));
  });
}

// Convert the time-slots response to an array of js Dates
export function convertAvailableTimeSlots(response) {
  const bodyContent = response?.body_content;

  if (bodyContent?.Result?.resultStatusFlag === 'FAIL') {
    return [];
  }
  const possibilities = bodyContent?.Availabilities?.Availability;

  const startAndEndTimes = possibilities?.map((date) => {
    return {
      startTime: new Date(Date.parse(date?.TimeSpan?.Start)),
      endTime: new Date(Date.parse(date?.TimeSpan?.End)),
    };
  });

  return startAndEndTimes;
}
